import { useState,useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Switch, Button } from "antd";
import { useSnackbar } from "notistack";
import { Save } from "lucide-react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { useUsers } from "../hooks/useUsers";
import { AxiosError } from "axios";

interface UpdateAccessControlProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  componentName: "invite" | "user";
  assign_all: boolean;
  inbox_rights:
    | {
        delete_right: boolean;
        compose_right: boolean;
        reply_right: boolean;
      }
    | undefined;
}

const UpdateAccessControl = ({
  isOpen,
  onClose,
  email,
  inbox_rights: initialInboxRights,
  componentName: componentType,
}: UpdateAccessControlProps) => {
  const { updateAccessControl } = useUsers();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // Local state for inbox_rights
  const [inboxRights, setInboxRights] = useState(initialInboxRights || {});

  useEffect(() => {
    setInboxRights(initialInboxRights || {});
  }, [initialInboxRights]);

  const { mutate, isPending } = useMutation({
    mutationKey: ["updateAccessControl"],
    mutationFn: updateAccessControl,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [
          componentType === "user" ? "assosited_users" : "inviteUsers",
        ],
      });
      enqueueSnackbar(data?.data?.message || "DONE", { variant: "default" });
      onClose();
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(
        (error?.response?.data as { message: string }).message || "ERROR",
        {
          variant: "error",
        }
      );
    },
  });

  // Handle switch toggle
  const handleToggle = (key: keyof typeof inboxRights, value: boolean) => {
    setInboxRights((prevRights) => ({
      ...prevRights,
      [key]: value,
    }));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] min-w-[540px]">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between">
            Access controller <p className="underline font-normal">{email}</p>
          </DialogTitle>
        </DialogHeader>
        <div className="mb-4">
          {[
            {
              key: "delete_right",
              label: "Delete",
              description: "Can be able to delete the mails from the inbox.",
            },
            {
              key: "compose_right",
              label: "Compose",
              description:
                "Can be able to compose and send the mails to the other users.",
            },
            {
              key: "reply_right",
              label: "Reply",
              description:
                "Can be able to reply to the mails that are received from the other users.",
            },
          ].map(({ key, label, description }) => (
            <div
              key={key}
              className="flex items-center justify-between mt-2 bg-gray-100 py-2 rounded-md px-[10px]"
            >
              <div className="text-gray-700">
                <h3 className="font-semibold text-gray-900">{label}</h3>
                <p className="text-sm">{description}</p>
              </div>
              <Switch
                size="small"
                checked={inboxRights[key as keyof typeof inboxRights]}
                onChange={(value) =>
                  handleToggle(key as keyof typeof inboxRights, value)
                }
              />
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button
            htmlType="submit"
            icon={
              <Save className="text-gray-700" strokeWidth={1.2} size={20} />
            }
            loading={isPending}
            onClick={() =>
              mutate({
                inbox_rights: inboxRights as {
                  delete_right: boolean;
                  compose_right: boolean;
                  reply_right: boolean;
                },
                email,
                queryKey: componentType,
              })
            }
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAccessControl;
