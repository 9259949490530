import {
  Modal,
  Input,
  ColorPicker,
  theme,
  Row,
  Col,
  Divider,
  Button,
  Dropdown,
  Popconfirm,
  Checkbox,
  Tooltip,
} from "antd";
import type { ColorPickerProps, MenuProps, PopconfirmProps } from "antd";
import { cyan, generate, green, presetPalettes, red } from "@ant-design/colors";
import React from "react";
import { Plus, MoreHorizontal, Trash2, PenIcon } from "lucide-react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader2, Info } from "lucide-react";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";

import { gmailApi } from "../api/gmail";

import { useUser } from "@/providers/userProvider";
import { useLabelSelect } from "@/providers/labelSelectContext";

import Text from "@/components/Text";
// import PromptWindow from "./prompt";

const Labels = () => {
  const { getLables, addLabel, updateLabel, deleteLabel, normalizeLabels } =
    gmailApi();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { labels, setLabels } = useLabelSelect();

  const [openDropdownIndex, setOpenDropdownIndex] = React.useState<
    number | null
  >(null);
  const [deleteState, setDeleteState] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isEditabel, setIsEditabel] = React.useState(false);
  const [color, setColor] = React.useState("");
  const [response, setResponse] = React.useState<{
    id: string;
    name: string;
    description: string;
  }>({ id: "", description: "", name: "" });

  const { data, status, refetch } = useQuery({
    queryKey: ["getLables"],
    queryFn: getLables,
    enabled: !!user?.inbox?.inbox_account_email,
  });

  React.useEffect(() => {
    if (data?.data.labels) {
      setLabels(data.data.labels);
    }
  }, [data, setLabels]);

  const { mutate: addLabelMutate, isPending } = useMutation({
    mutationKey: ["createLabel"],
    mutationFn: () => addLabel(response.name, color, response.description),
    onSuccess: (data) => {
      enqueueSnackbar(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["threadLabels"],
      });
      refetch();
      setIsModalVisible(false);
      setResponse({ id: "", description: "", name: "" });
      setColor("");
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar((error.response?.data as { message: string }).message, {
        variant: "error",
      });
    },
  });

  const { mutate: updateLabelMutate, isPending: updatePending } = useMutation({
    mutationKey: ["updateLabel"],
    mutationFn: () =>
      updateLabel(response.id, response.name, color, response.description),
    onSuccess: (data) => {
      enqueueSnackbar(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["threadLabels"],
      });
      refetch();
      setIsModalVisible(false);
      setResponse({ id: "", description: "", name: "" });
      setColor("");
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar((error.response?.data as { message: string }).message, {
        variant: "error",
      });
    },
  });

  const { mutate: deleteLabelMutate, isPending: deletePending } = useMutation({
    mutationKey: ["deleteLabel"],
    mutationFn: () => deleteLabel(response.id),
    onMutate: () => {
      const hide = enqueueSnackbar("Deleting group...", {
        variant: "warning",
        persist: true,
        key: "delete-group",
      });
      return { hide };
    },
    onSuccess: (data) => {
      closeSnackbar("delete-group");
      enqueueSnackbar(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["threadLabels"],
      });
      refetch();
      setIsModalVisible(false);
      setResponse({ id: "", description: "", name: "" });
      setColor("");
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar((error.response?.data as { message: string }).message, {
        variant: "error",
      });
    },
  });

  const handleCretaeLabel = () => {
    if (!response.name) {
      enqueueSnackbar("Please enter a group name.", { variant: "error" });
      return;
    }
    if (!color) {
      enqueueSnackbar("Please select a color.", { variant: "error" });
      return;
    }
    if (!response.description) {
      enqueueSnackbar("Please enter a description for the group.", {
        variant: "error",
      });
      return;
    }
    if (data?.data.labels.find((label) => label.name === response.name)) {
      enqueueSnackbar("Group name already exists.", { variant: "error" });
      return;
    }
    addLabelMutate();
  };

  const handleUpdateLabel = () => {
    if (response.name === data?.data.labels[openDropdownIndex ?? 0].name) {
      enqueueSnackbar("Please enter a new group name.", { variant: "error" });
      return;
    }
    if (!response.name) {
      enqueueSnackbar("Please enter a group name.", { variant: "error" });
      return;
    }
    if (!color) {
      enqueueSnackbar("Please select a color.", { variant: "error" });
      return;
    }
    if (!response.description) {
      enqueueSnackbar("Please enter a description for the group.", {
        variant: "error",
      });
      return;
    }
    updateLabelMutate();
  };

  const confirm: PopconfirmProps["onConfirm"] = () => {
    deleteLabelMutate();
    setDeleteState((prev) => ({ ...prev, [openDropdownIndex!]: false }));
    setOpenDropdownIndex(null);
  };

  const cancel: PopconfirmProps["onCancel"] = () => {
    setIsModalVisible(false);
    setOpenDropdownIndex(null);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <p>Edit</p>,
      icon: <PenIcon size={16} strokeWidth={1.2} />,
      onClick: () => {
        setIsModalVisible(true);
        setIsEditabel(true);
        setResponse(
          data?.data.labels[openDropdownIndex!] ?? {
            id: "",
            description: "",
            name: "",
          }
        );
        setColor(data?.data.labels[openDropdownIndex!].color ?? "");
        setOpenDropdownIndex(null);
      },
    },
    {
      key: "2",
      danger: true,
      label: (
        <Popconfirm
          title="Delete the group"
          description={
            <p className="text-gray-600">
              Are you sure you want to delete the{" "}
              <span className="font-semibold text-gray-900">
                {data?.data?.labels[openDropdownIndex!]?.name?.toLowerCase()}
              </span>{" "}
              ?
            </p>
          }
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
          arrow={false}
          okButtonProps={{ loading: deletePending }}
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Trash2 size={16} strokeWidth={1.2} style={{ marginRight: 6 }} />
            <span>Delete</span>
          </div>
        </Popconfirm>
      ),
      onClick: () => {
        setDeleteState((prev) => ({ ...prev, [openDropdownIndex ?? 2]: true }));
        setResponse(
          data?.data.labels[openDropdownIndex!] ?? {
            id: "",
            description: "",
            name: "",
          }
        );
      },
    },
  ];

  const { mutate } = useMutation({
    mutationKey: ["normalize-labels"],
    mutationFn: normalizeLabels,
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (labels) {
        const updates = labels.map((label) => ({
          id: label.id,
          isSelected: label.isSelected,
        }));
        mutate({
          updates,
        });
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [labels, mutate]);

  return (
    <div className="mx-[20px] pt-[15px] border-t w-[175px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[5px]">
          <Tooltip title="select all group">
            {" "}
            <Checkbox
              checked={labels?.every((l) => l.isSelected)}
              onChange={() => {
                const allSelected = labels.every((l) => l.isSelected);
                setLabels((prev) =>
                  prev.map((l) => ({ ...l, isSelected: !allSelected }))
                );
              }}
            />
          </Tooltip>
          <Text bold>Mail Groups</Text>
          <Tooltip
            color="white"
            title={
              <div className="p-3">
                <h4 className="font-semibold text-base text-gray-800">
                  Information
                </h4>
                <div>
                  <ul className="list-disc ml-4 mt-2 text-sm text-gray-700 space-y-1">
                    <li>
                      To select only a single group, double-click on the desired
                      group to select it and deselect others.
                    </li>
                    <li>
                      To toggle all labels on or off, use the checkbox:
                      <ul className="list-inside list-disc pl-4">
                        <li>
                          If all labels are selected, clicking the checkbox will
                          deselect all.
                        </li>
                        <li>
                          If any label is unselected, clicking the checkbox will
                          select all.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Single-click a label to toggle its selection individually.
                    </li>
                    <li>
                      Hover over a label for options. Click the menu icon to
                      access additional actions.
                    </li>
                  </ul>
                </div>
              </div>
            }
          >
            <Info size={13} className="text-gray-600 cursor-pointer" />
          </Tooltip>
        </div>
        {status === "success" && data?.data.labels && (
          <Plus
            size={30}
            className="translate-x-3 hover:text-gray-800 hover:bg-gray-100 p-2 rounded-full cursor-pointer hover:scale-110"
            onClick={() => {
              setIsEditabel(false);
              setIsModalVisible(true);
              setResponse({ id: "", description: "", name: "" });
              setColor("");
            }}
          />
        )}
      </div>
      <div className="max-h-[200px] scroll-smooth overflow-y-scroll z-20 w-full">
        {user?.inbox?.inbox_account_email === null ||
          (status === "pending" && (
            <div>
              <Loader2
                size={16}
                strokeWidth={1.2}
                className="text-gray-600 animate-spin mx-auto mt-2"
              />
            </div>
          ))}
        {status === "error" && <div>Error fetching labels</div>}
        {status === "success" && labels?.length < 0 ? (
          <p className="text-center text-sm text-gray-600 mt-2">
            No group found
          </p>
        ) : (
          labels?.map((label, index) => (
            <div
              key={index}
              className={
                "flex items-center justify-between my-2 cursor-pointer transition group w-full"
              }
            >
              <div className="flex items-center mt-1 group">
                <div
                  className={`h-[15px] w-[15px] rounded-md border-2 relative hover:scale-110 z-40`}
                  style={{ borderColor: label.color }}
                  onClick={() => {
                    setLabels((prev) =>
                      prev.map((l) =>
                        l.name === label.name
                          ? { ...l, isSelected: !l.isSelected }
                          : l
                      )
                    );
                  }}
                  onDoubleClick={() => {
                    // on double click just select the label and deselect others
                    setLabels((prev) =>
                      prev.map((l) =>
                        l.name === label.name
                          ? { ...l, isSelected: true }
                          : { ...l, isSelected: false }
                      )
                    );
                  }}
                >
                  {label.isSelected && (
                    <div
                      className={`h-[9px] w-[9px] rounded-sm absolute top-[0.8px] left-[1px]`}
                      style={{ backgroundColor: label.color }}
                    />
                  )}
                </div>
                <p className="ml-2 text-sm capitalize leading-[16px]  hover:font-semibold hover:text-gray-800 ">
                  {label.name?.toLowerCase()}
                </p>
              </div>
              <span
                className={
                  openDropdownIndex === index
                    ? "text-sm hidden text-gray-600 group-hover:hidden"
                    : "text-sm text-gray-600 group-hover:hidden"
                }
              >
                {label?.messageCount || 0}
              </span>
              <Dropdown
                menu={{ items }}
                onOpenChange={(open) => {
                  if (!open) {
                    setOpenDropdownIndex(null);
                    setDeleteState((prev) => ({ ...prev, [index]: false }));
                  }
                }}
                open={deleteState[index] || openDropdownIndex === index}
                key={index}
                placement="bottomLeft"
                arrow
                className="z-40"
              >
                <Button
                  size="small"
                  className={
                    openDropdownIndex === index
                      ? "bg-gray-100 h-[18px] z-20"
                      : "hidden group-hover:block h-[18px] z-50"
                  }
                  icon={<MoreHorizontal size="16" strokeWidth={1.2} />}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenDropdownIndex(
                      openDropdownIndex === index ? null : index
                    );
                    setDeleteState((prev) => ({ ...prev, [index]: false }));
                  }}
                />
              </Dropdown>
            </div>
          ))
        )}
      </div>
      <Modal
        title={isEditabel ? "Edit Group" : "New Group"}
        open={isModalVisible}
        okText={isEditabel ? "Update" : "Create"}
        okButtonProps={{
          loading: isEditabel ? updatePending : isPending,
        }}
        onOk={isEditabel ? handleUpdateLabel : handleCretaeLabel}
        onClose={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div>
          <label htmlFor="name" className="text-sm font-semibold">
            Please enter a new group name
          </label>
          <Input
            id="name"
            placeholder="Group name"
            value={response.name?.toLowerCase()}
            onChange={(e) =>
              setResponse((prev) => {
                return { ...prev, name: e.target.value };
              })
            }
          />
        </div>
        <div className="mt-2">
          <div className="flex items-center justify-between">
            <label htmlFor="name" className="text-sm font-semibold">
              Please write the group description
            </label>
            {!isEditabel && (
              <Tooltip
                color="white"
                className="flex items-center gap-2"
                rootClassName="!min-w-[510px]"
                title={
                  <div className="p-3 w-[500px]">
                  <h4 className="font-semibold text-base text-gray-800">Guidelines for Writing Effective Prompts in Wingg AI</h4>
                  <div>
                    <ul className="list-disc ml-4 mt-2 text-sm text-gray-700 space-y-1">
                      <li>
                        <strong>Be Specific About the Grouping Criteria:</strong> Define clear rules for grouping emails based on their
                        <span className="italic"> subject</span>, <span className="italic">body</span>, or <span className="italic">sender domain/email address</span>.
                        <ul className="list-inside list-disc pl-4 mt-1">
                          <li>Example: Group all emails related to "Job Alerts" by identifying keywords in the subject or body like "job opening," "vacancy," or "career opportunity."</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Include Examples for Better Context:</strong> Providing examples makes the intent of the prompt clear to the AI.
                        <ul className="list-inside list-disc pl-4 mt-1">
                          <li><strong>Subject:</strong> "Exciting Job Opportunity at XYZ Corp"</li>
                          <li><strong>Body:</strong> "We're hiring for multiple positions in our engineering team."</li>
                          <li><strong>Sender Email:</strong> jobs@xyzcorp.com</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Use Keywords for Filtering:</strong> Specify keywords that should be present in the subject or body of the email to help categorize it.
                        <ul className="list-inside list-disc pl-4 mt-1">
                          <li>Example Prompt: Group emails where the body contains phrases like "job opening," "apply now," or "we're hiring."</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Mention Sender Domain or Email:</strong> If grouping is based on the sender, specify exact email addresses or domains.
                        <ul className="list-inside list-disc pl-4 mt-1">
                          <li>Example: Group all emails from domains like <code>@jobportal.com</code> or from <code>alerts@careerbuilder.com</code>.</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Avoid Overlapping or Conflicting Instructions:</strong> Keep prompts concise and focused to avoid confusion or misclassification.
                        <ul className="list-inside list-disc pl-4 mt-1">
                          <li><strong>Do:</strong> Group emails with the subject containing "internship opportunity."</li>
                          <li><strong>Don't:</strong> Group emails with job-related subjects and unrelated newsletters.</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Leverage Subject and Body Separately:</strong> If you want the AI to prioritize one part of the email (e.g., subject over body), make it explicit.
                        <ul className="list-inside list-disc pl-4 mt-1">
                          <li>Example: Prioritize grouping emails where the subject mentions "Job Alert" even if the body doesn't.</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Test and Refine Your Prompt:</strong> Review how Wingg categorizes emails based on your prompt and adjust as needed. Use feedback to fine-tune for better results.
                      </li>
                    </ul>
                  </div>
                </div>
                
                }
              >
                <span className="text-orange-500 text-sm">Example</span> <Info size={13} className="text-orange-500 cursor-pointer" />
              </Tooltip>
            )}
          </div>
          {isEditabel ? (
            <p className="py-[5px] text-gray-700">{response.description}</p>
          ) : (
            <Input.TextArea
              id="name"
              placeholder="Tell us how this group should behave or work"
              className="max-h-[200px] mt-2"
              disabled={isEditabel}
              value={response.description}
              onChange={(e) => {
                setResponse((prev) => {
                  return { ...prev, description: e.target.value };
                });
              }}
            />
          )}
        </div>
        {/* <PromptWindow /> */}
        <div className="mt-[10px]">
          <label htmlFor="name" className="text-sm font-semibold">
            Please select a color:
          </label>
          <div className="flex items-center justify-start gap-[10px]">
            <ColorPickerComp color={color} setColor={setColor} />
            {color && (
              <div className="text-gray-600">
                Selected color:{" "}
                <span className={`font-mono text-['${color}']`}>{color}</span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Labels;

type Presets = Required<ColorPickerProps>["presets"][number];

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({
    label,
    colors,
  }));

interface ColorGenInput {
  setColor: React.Dispatch<React.SetStateAction<string>>;
  color: string;
}

const ColorPickerComp: React.FC<ColorGenInput> = ({ color, setColor }) => {
  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });
  const customPanelRender: ColorPickerProps["panelRender"] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" style={{ height: "auto" }} />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );
  return (
    <ColorPicker
      defaultValue={token.colorPrimary}
      styles={{ popupOverlayInner: { width: 480 } }}
      presets={presets}
      panelRender={customPanelRender}
      value={color}
      arrow
      onChange={(value) => setColor(value.toHexString())}
    />
  );
};
