import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "@/providers/userProvider";
import { appRoutes } from "@/utils/paths";

const RequirePayment: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useUser();

  if (user?.isPaid) {
    return <Navigate to={appRoutes.dashboard.PLAN} replace />;
  }

  return <>{children}</>;
};

export default RequirePayment;
