import React from "react";

export const useScroller = (
  selectedThreadRef: React.MutableRefObject<HTMLDivElement | null>,
  listRef: React.MutableRefObject<HTMLDivElement | null>,
) => {
  const scrollToThread = () => {
    if (selectedThreadRef.current) {
      selectedThreadRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return {
    scrollToThread,
    scrollToTop,
  };
};
