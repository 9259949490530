/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosError, CancelTokenSource } from "axios";
import axiosRetry from "axios-retry";

import storage from "@/utils/storage";
import { TOKENS } from "@/constant";
import { notification } from "antd";

const http: AxiosInstance = axios.create({
  baseURL:
    (import.meta.env.VITE_REST_API_URL as string) ||
    "https://sapi.mywingg.com/api",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":
      (import.meta.env.VITE_REST_API_URL as string) ||
      "https://sapi.mywingg.com/api",
  },
});

axiosRetry(http, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay || 2000,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    return (
      error.code === "ECONNABORTED" ||
      error.code === "ECONNREFUSED" ||
      error.code === "ERR_NETWORK" ||
      error.code === "ECONNRESET" ||
      error.response?.status === 502
    );
  },
});


http.interceptors.request.use(
  (config) => {
    const store = storage.get(TOKENS)?.access_token;
    if (store && config.headers) {
      config.headers.Authorization = `Bearer ${store}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle 429 (Too Many Requests)
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    // Check if the error is a 429 (Too Many Requests) status
    if (error.response?.status === 429) {
      // You can implement any custom handling for rate limit errors here
      // For example, you can show a notification to the user or redirect them to a waiting page
      const message =
        typeof error.response.data === "string"
          ? error.response.data
          : (error as any)?.response?.data?.message ||
            "Too many requests. Please try again later.";

      // You can dispatch an error notification or handle the rate limiting logic here
     
        notification.error({
          message: "Rate Limit Exceeded",
          description: message,
        });
      
 
      // For now, we just reject the error
    }

    // Reject the error to propagate it down the chain
    return Promise.reject(error);
  }
);

export const createCancelToken = (): CancelTokenSource => {
  return axios.CancelToken.source();
};

export default http;
