import { InviteUser } from "../hooks/types";
import { CalendarCheck, SquarePen, Trash, Reply } from "lucide-react";

import { ColumnDef } from "@tanstack/react-table";

export const InviteDataColumns: ColumnDef<InviteUser>[] = [
  {
    accessorKey: "email",
    header: () => <div className="text-left">Email</div>,
    cell: ({ row }) => {
      return row.original.email;
    },
  },
  {
    accessorKey: "expireAt",
    header: () => <div className="text-left ml-[50px]">Expire at</div>,
    cell: ({ row }) => {
      const data = row.original.expire_at;
      const date = new Date(data);
      return (
        <div className="items-center justify-start ml-[50px] flex gap-1 text-[#7c7c7c] dark:text-gray-400">
          <CalendarCheck color="gray" size={22} strokeWidth={1.2} />
          {date?.getTime() > 0 ? (
            <>
              {date?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                calendar: "gregory",
                numberingSystem: "latn",
                localeMatcher: "lookup",
                weekday: "short",
              })}
              {
                // date left
                date.getTime() - new Date().getTime() > 0 ? (
                  <span className="text-green-500">
                    {" "}
                    (in {date.getDate() - new Date().getDate()} day)
                  </span>
                ) : (
                  <span className="text-red-500"> (expired)</span>
                )
              }
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "Access Control",
    header: () => <div className="text-left">Access Control</div>,
    cell: ({ row }) => {
      const rights = row.original?.inbox_rights;
      return (
        <div className="flex items-center gap-[5px]">
          {rights?.compose_right && (
            <p className="bg-orange-200 px-2 py-1 rounded-md font-semibold text-gray-800 flex items-center gap-1">
              <SquarePen size={18} strokeWidth={1.2} />
              Compose
            </p>
          )}
          {rights?.delete_right && (
            <p className="bg-red-200 px-2 py-1 rounded-md font-semibold text-gray-800 flex items-center gap-1">
              <Trash size={18} strokeWidth={1.2} />
              Delete
            </p>
          )}
          {rights?.reply_right && (
            <p className="bg-green-200 px-2 py-1 rounded-md font-semibold text-gray-800 flex items-center gap-1">
              <Reply size={18} strokeWidth={1.2} />
              Reply
            </p>
          )}
          {rights?.compose_right ||
          rights?.delete_right ||
          rights?.reply_right ? null : (
            <p className="text-orange-700 font-semibold">No Rights</p>
          )}
        </div>
      );
    },
  },
];
