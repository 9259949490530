import Headnav from "@/components/Navbars/Headnav";
import useHead from "@/hooks/useHead";

import Plan from "./components/Plan";
import PaymentHistory from "./components/History";
import Contact from "./components/Support";

const Payments = () => {
  useHead({
    title: "Payments | Wingg",
    description: "Payments page of wingg",
  });

  return (
    <div className="overflow-scroll h-full rounded-xl">
      <div className="sticky top-0 bg-white z-10">
        <Headnav heading="Billing and Payments" />
      </div>
      <div className="grid grid-cols-4 gap-4 mx-[20px] my-[20px]">
        <div className="col-span-4 xl:col-span-1 rounded-lg">
          <Plan />
          <Contact />
        </div>
        <div className="col-span-4 xl:col-span-3  rounded-lg">
          <PaymentHistory />
        </div>
      </div>
    </div>
  );
};

export default Payments;
