import http from "@/http";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";

import { LoginResponse } from "@/model/user.model";
import { useNavigate } from "react-router-dom";
import { useUserDispatch } from "@/providers/userProvider";
import { useTokenDispatch, TokenActions } from "@/providers/useAuthProvider";

import { appRoutes } from "@/utils/paths";
import storage from "@/utils/storage";

const useLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useUserDispatch();
  const tokenDispatch = useTokenDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      const response = await http.post<LoginResponse>("/auth/login", {
        email,
        password,
      });
      setLoading(false);
      if (response.data.user.is_2fa_enabled) {
        return window.location.assign(
          `${appRoutes.auth.MAIN_TWO_FA}/?token=${btoa(email)}`
        );
      }
      dispatch?.setAccountId(response.data.user.userId);
      tokenDispatch({
        type: TokenActions.setTokens,
        payload: {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        },
      });
      navigate(storage.get("redirect") || appRoutes.dashboard.INBOX.ROOT);
      return storage.remove("redirect");
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof AxiosError) {
        // if (error.response && error.response.status === 429) {
        //   const message =
        //     typeof error.response?.data === "string"
        //       ? error.response.data
        //       : error.response?.data?.message ||
        //         "Too many requests, please try again later.";
        //   enqueueSnackbar(message || "Too many requests", { variant: "error" });
        // }
        if (error.response?.data) {
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
          });
        } else
          enqueueSnackbar(error?.message || "An unexpected error occurred.", {
            variant: "error",
          });
      }
    }
  };

  return { login, loading };
};

export default useLogin;
