import { Checkbox, Dropdown, Space } from "antd";
import { ChevronDown } from "lucide-react";
import type { MenuProps } from "antd";

import { SelectedMessage } from "./List";

const CustomCheckbox = ({
  setSelectedMessage,
  selectedMessage,
}: {
  setSelectedMessage: (value: { type: SelectedMessage } | undefined) => void;
  selectedMessage: { type: SelectedMessage } | undefined;
}) => {
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "all":
        setSelectedMessage({
          type: SelectedMessage.all,
        });
        break;
      case "none":
        setSelectedMessage({
          type: SelectedMessage.none,
        });
        break;
      case "read":
        setSelectedMessage({
          type: SelectedMessage.read,
        });
        break;
      case "unread":
        setSelectedMessage({
          type: SelectedMessage.unread,
        });
        break;
      default:
        break;
    }
  };

  const menu: MenuProps["items"] = [
    {
      key: "all",
      className:
        selectedMessage?.type === SelectedMessage.all ? "bg-gray-100" : "",
      label: "All",
      onClick: handleMenuClick,
    },
    {
      key: "none",
      label: "None",
      onClick: handleMenuClick,
    },
    {
      key: "read",
      className:
        selectedMessage?.type === SelectedMessage.read ? "bg-gray-100" : "",
      label: "Read",
      onClick: handleMenuClick,
    },
    {
      key: "unread",
      label: "Unread",
      className:
        selectedMessage?.type === SelectedMessage.unread ? "bg-gray-100" : "",
      onClick: handleMenuClick,
    },
  ];

  return (
    <Space className="border h-[30px] rounded-md px-1">
      <Checkbox
        checked={
          selectedMessage?.type === SelectedMessage.all
            ? true
            : selectedMessage?.type === SelectedMessage.none
            ? false
            : undefined
        }
        onChange={(e) => {
          setSelectedMessage({
            type: e.target.checked ? SelectedMessage.all : SelectedMessage.none,
          });
        }}
      />
      <Dropdown menu={{ items: menu }}>
        <ChevronDown size={15} />
      </Dropdown>
    </Space>
  );
};

export default CustomCheckbox;
