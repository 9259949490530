import React from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AtSign } from "lucide-react";
import { useSnackbar } from "notistack";
import {Switch} from 'antd'

import { Button } from "@/components/ui/button";
// import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/components/ui/select";

import { useUsers } from "../hooks/useUsers";

import { allAccess } from "@/types/role";
import { useUser } from "@/providers/userProvider";

interface InviteUserProps {
  isOpen: boolean;
  onClose: () => void;
  refetch?: () => void;
}

interface InviteUserForm {
  email: string,
  assign_all: boolean;
  role: string;
  delete_right: boolean
  compose_right: boolean
  reply_right: boolean
}

interface Status {
  value: string;
}

const InviteUser = ({ isOpen, onClose, refetch }: InviteUserProps) => {
  const { inviteUser } = useUsers();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [userRole, setUserRole] = React.useState<Status[]>(
    allAccess.map((role) => ({ value: role }))
  );

  React.useEffect(() => {
    if (user?.role === "manager") {
      setUserRole([userRole[2]]);
    } else {
      setUserRole([userRole[1], userRole[2], userRole[3]]);
    }
  }, [user]);

  const { isPending, mutateAsync } = useMutation({
    mutationKey: ["inviteUser"],
    mutationFn: inviteUser,
    onSuccess: (data) => {
      enqueueSnackbar(data.data.message,{variant: "default"});
      reset();
      refetch && refetch();
    },
    onError: (error) => {
      enqueueSnackbar(
        ((error as AxiosError).response?.data as { message: string })?.message,{variant: "error"}
      );
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<InviteUserForm>({
    mode: "onSubmit",
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required("Email is required"),
        assign_all: yup.boolean().default(false),
        role: yup.string().default("Member"),
        delete_right: yup.boolean().default(false),
        compose_right: yup.boolean().default(false),
        reply_right: yup.boolean().default(false)
      })
    ),
    defaultValues: {
      email: "",
      role: "Member",
      assign_all: false,
      delete_right: false,
      compose_right: false,
      reply_right: false
    },
  });

  const onSubmit = async (data: InviteUserForm) => {
    try {
      await mutateAsync({ ...data });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Invite user</DialogTitle>
          <DialogDescription>
            Invite a new user to collaborate on your inbox.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 pb-4">
            <div>
              <Label htmlFor="name">email</Label>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="email"
                    type="email"
                    className="mt-1"
                    error={errors.email?.message}
                    addornment={<AtSign size={20} strokeWidth={1.2} />}
                  />
                )}
              />
            </div>
            {/* <div>
              <Label htmlFor="username" className="text-right">
                Role
              </Label>
              <Controller
                control={control}
                name="role"
                render={({ field }) => (
                  <Select
                    {...field}
                    value="Set role"
                    autoComplete="on"
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger className="mt-1 capitalize">
                      <SelectValue>{field.value || "Set role"}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {userRole.map((role) => (
                        <SelectItem
                          key={role?.value}
                          value={role?.value}
                          className="capitalize"
                        >
                          {role?.value}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
              {errors.role && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.role.message}
                </p>
              )}
            </div> */}
            {/* <div className="flex items-center gap-[10px]">
              <Controller
                control={control}
                name="assign_all"
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                )}
              />
              <p className="text-gray-700 dark:text-gray-400 text-sm">
                Assign user to all workflows
              </p>
            </div> */}
            <div className="">
              <p className="font-semibold text-gray-800">Access Control</p>
              <div className="flex items-center justify-between mt-2 bg-gray-100 py-2 rounded-md px-[10px]">
                <div className="text-gray-700">
                  <h3 className="font-semibold text-gray-900">Delete</h3>
                  <p className="text-sm">Can be able to delete the mails from the inbox.</p>
                </div>
                <Controller 
                  control={control}
                  name="delete_right"
                  render={({ field }) => (
                    <Switch size="small" checked={field.value} onChange={field.onChange} />
                  )}
                />
              </div>
              <div className="flex items-center justify-between mt-2 bg-gray-100 py-2 rounded-md px-[10px]">
                <div className="text-gray-700">
                  <h3 className="font-semibold text-gray-900">Compose</h3>
                  <p className="text-sm">
                    Can be able to compose and send the mails to the other users.
                  </p>
                </div>
                <Controller 
                  control={control}
                  name="compose_right"
                  render={({ field }) => (
                    <Switch size="small" checked={field.value} onChange={field.onChange} />
                  )}
                />
              </div>
              <div className="flex items-center justify-between mt-2 bg-gray-100 py-2 rounded-md px-[10px]">
                <div className="text-gray-700">
                  <h3 className="font-semibold text-gray-900">Reply</h3>
                  <p className="text-sm">
                    Can be able to reply to the mails that are received from the other users.
                  </p>
                </div>
              <Controller
                control={control}
                name="reply_right"
                render={({ field }) => (
                  <Switch size="small" checked={field.value} onChange={field.onChange} />
                )}
              />
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" className="w-[100px]" loading={isPending}>
              Send
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUser;
