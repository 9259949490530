import React from "react";
import {
  Search,
  MoreHorizontal,
  Settings2,
  ChevronDown,
  UploadCloud,
  ScanEye,
  Repeat2,
  GanttChartSquare,
  Download,
  Trash,
  Calendar,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  Checkbox,
  Pagination,
  Button,
  Input,
  Space,
  Upload,
  message,
  DatePicker,
} from "antd";
import type {
  TableColumnsType,
  TableProps,
  InputRef,
  TableColumnType,
  UploadProps,
} from "antd";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import Highlighter from "react-highlight-words";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";

import DownloadReportButton from "./components/DownloadButton";
import DeleteScanData from "./components/DeleteScanData";
import DeleteMultipleFiles from "./components/DeleteMultipleFile";
import EditDocument from "./components/EditDocument";

import Previewer from "./components/Previewer";

import { useNewWorkflow } from "./hooks/useWorkflow";
import useDeleteFile from "./hooks/useDeleteFile";

import useUploadFile from "@/hooks/useUploader";
// import useWebSocket from "@/hooks/useWebsocket";

// import { NotificationEvent } from "@/types/notifications.types";
import { Document } from "@/types/document";

import { ReactComponent as GmailIcon } from "@/assets/svgs/gmail.svg";
import { ReactComponent as DevicesIcon } from "@/assets/svgs/computer.svg";

function isDateExpired(dateString: string): boolean {
  if (!dateString) {
    return false;
  }
  const data = new Date(dateString);
  return data.getTime() < new Date().getTime();
}

type TableRowSelection<T extends object = object> =
  TableProps<T>["rowSelection"];

type DataIndex = keyof Document;

const WorkflowDataTable: React.FC = () => {
  const [documentInfo, setDocumentInfo] = React.useState<Document>();
  const [hoveredRecord, setHoveredRecord] = React.useState<string | null>(null);

  const navigate = useNavigate();

  const handleDownloadFile = async (file: Document) => {
    try {
      const response = await fetch(file.document_location);
      if (!response.ok)
        return message.error("Failed to download file. Please try again");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.file_name;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      message.error("Failed to download file");
    }
  };

  const handleMultipleFileModalOpener = (
    document: Document,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows: any[] | undefined
  ) => {
    if (rows && rows?.length > 1) {
      setSelectedDocument(rows.map((row) => row) as Document[]);
      setShowDeleteMultipleDialog(true);
    } else {
      setDocumentInfo(document);
      setShowDeleteDialog(true);
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<Document> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="p-4">
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Search size={16} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Search size={16} style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const dataIndexValue = record[dataIndex]?.toString().toLowerCase();
      return dataIndexValue
        ? dataIndexValue.includes(value.toString().toLowerCase())
        : false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const getDateColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<Document> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="p-4">
        <DatePicker
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(date) => setSelectedKeys(date ? [date] : [])}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Search size={16} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Calendar size={16} style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const dataIndexValue = record[dataIndex as keyof Document]
        ?.toString()
        .toLowerCase();
      return dataIndexValue
        ? dataIndexValue.includes(value.toString().toLowerCase())
        : false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<Document> = [
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Status</div>
      ),
      width: 125,
      dataIndex: "status",
      key: "status",
      fixed: "left",
      filters: [
        {
          text: "Ready",
          value: "Ready",
        },
        {
          text: "Processing",
          value: "Processing",
        },
        {
          text: "Fail",
          value: "Fail",
        },
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Rejected",
          value: "Rejected",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
      render: (status) => {
        let color = "gray";
        {
          if (status === "Ready") {
            color = "blue";
          } else if (status === "Processing") {
            color = "yellow";
          } else if (status === "Fail") {
            color = "red";
          } else if (status === "Approved") {
            color = "green";
          } else if (status === "Rejected") {
            color = "orange";
          } else if (status === "Pending") {
            color = "yellow";
          } else {
            color = "gray";
          }
        }
        return {
          children: (
            <>
              {status === "Processing" ? (
                <div className="mx-auto flex w-full max-w-lg items-center justify-center">
                  <div className="relative z-10 flex w-auto items-center overflow-hidden rounded-full border border-yellow-300 p-[1.5px]">
                    <div className="animate-rotate absolute inset-0 h-[10px] w-full text-center rounded-full bg-[conic-gradient(#f2876c_20deg,transparent_120deg)]" />
                    <div
                      className={`relative z-20 flex w-auto items-center justify-center rounded-full bg-${color}-400 p-1`}
                    >
                      <div
                        className={`inline-block h-full flex-1 items-center justify-center rounded-full bg-transparent px-2 py-1 text-${color}-900 focus:outline-none focus:ring-1 focus:ring-cyan-500`}
                      >
                        {status}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`text-${color}-700 font-popins capitalize text-center bg-${color}-200 rounded-full w-auto py-2`}
                >
                  {status}
                </div>
              )}
            </>
          ),
        };
      },
      onFilter: (value, record) => record.status === value,
    },
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Invoice name</div>
      ),
      width: 250,
      dataIndex: "file_name",
      key: "file_name",
      fixed: "left",
      onCell: (record) => ({
        onMouseOver: () => {
          setHoveredRecord(record.document_location);
        },
        onMouseLeave: () => {
          setHoveredRecord(null);
        },
        onClick: () => {
          navigate("ground/" + record.job_id + `#${record?.ref_id}`, {
            state: {
              fileName: record?.file_name,
              document_location: record?.document_location,
              ref_id: record?.ref_id,
            },
          });
        },
      }),
      className: "cursor-pointer hover:underline",
      render: (_, record) => {
        const fileName = record?.file_name as string;
        return (
          <>
            {record.status !== "Ready" && (
              <div className="capitalize cursor-not-allowed text-[#44A5FF] flex items-center gap-[5px] dark:text-blue-600 group max-w-[260px] min-w-[200px]">
                {fileName.length > 30
                  ? fileName?.slice(0, 30) + "..."
                  : fileName}
              </div>
            )}
          </>
        );
      },
      ...getColumnSearchProps("file_name"),
    },
    Table.EXPAND_COLUMN,
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Created at</div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 350,
      sorter: (a, b) =>
        new Date(a?.createdAt as string).getTime() -
        new Date(b?.createdAt as string).getTime(),
      render: (date) => (
        <span className="text-[#7c7c7c] font-popins">
          {new Date(date).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            calendar: "gregory",
            numberingSystem: "latn",
            localeMatcher: "lookup",
            weekday: "short",
          })}
        </span>
      ),
      // ...getDateColumnSearchProps("createdAt"),
    },
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Invoice ID</div>
      ),
      dataIndex: "invoice_id",
      key: "invoice_id",
      width: 170,
      render: (invoice_id) => ({
        children: (
          <div className="text-[#7c7c7c] font-popins text-sm">{invoice_id}</div>
        ),
      }),
      ...getColumnSearchProps("invoice_id"),
    },
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Due date</div>
      ),
      dataIndex: "due_date",
      key: "due_date",
      width: 150,
      // className:  isDateExpired("12/07/2020") ? "text-red-500" : "text-[#7c7c7c]",
      render: (date) => (
        <span
          className={`${
            isDateExpired(date) ? "text-red-500" : "text-[#7c7c7c]"
          }`}
        >
          {date}
        </span>
      ),
      ...getDateColumnSearchProps("due_date"),
    },
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Vendor name</div>
      ),
      dataIndex: "vendor_name",
      key: "vendor_name",
      width: 180,
      render: (vendor_name) => ({
        children: <div className="text-[#7c7c7c]">{vendor_name}</div>,
      }),
      ...getColumnSearchProps("vendor_name"),
    },
    {
      title: () => (
        <div className="text-left text-gray-700 font-popins">Source</div>
      ),
      dataIndex: "source",
      key: "source",
      width: 300,
      render: (source) => ({
        children: (
          <div className="text-[#7c7c7c] flex items-center gap-2">
            {source.toLowerCase() !== "manual" ? (
              <GmailIcon height={28} width={28} />
            ) : (
              <DevicesIcon height={28} width={28} />
            )}
            {source}
          </div>
        ),
      }),
    },
    {
      key: "actions",
      fixed: "right",
      width: 78,
      className: "text-center",
      render: (document) => ({
        children: (
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full">
              <Button type="text" className="h-7">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="font-popins">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem
                onClick={() =>
                  navigate(
                    "ground/" + document.job_id + `#${document?.ref_id}`,
                    {
                      state: {
                        fileName: document?.file_name,
                        document_location: document?.document_location,
                        workflow_id: document?.workflow_id,
                        ref_id: document?.ref_id,
                      },
                    }
                  )
                }
                className="flex items-center gap-1 text-[#7c7c7c]"
              >
                <ScanEye size={22} strokeWidth={1.2} />
                Open
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() =>
                  navigate(
                    "ground/" + document.job_id + `#${document?.ref_id}`,
                    {
                      state: {
                        fileName: document.file_name,
                        document_location: document.document_location,
                        workflow_id: document.workflow_id,
                        ref_id: document?.ref_id,
                        re_extract: true,
                      },
                    }
                  )
                }
                className="flex items-center gap-1 text-[#7c7c7c]"
              >
                <Repeat2 size={22} strokeWidth={1.2} />
                Re-extract
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => handleDownloadFile(document)}
                className="flex items-center gap-1 text-[#7c7c7c]"
              >
                <Download size={22} strokeWidth={1.2} />
                Download invoice
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => handleEditSheetOpener(document)}
                className="flex items-center gap-1 text-[#7c7c7c]"
              >
                <GanttChartSquare size={22} strokeWidth={1.2} />
                Rename
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() =>
                  handleMultipleFileModalOpener(
                    document,
                    selectedRowKeys.map((key) =>
                      data.find((item) => item.documentId === key.toString())
                    )
                  )
                }
                className="flex items-center gap-1 text-[#7c7c7c] hover:!text-red-500"
              >
                <Trash size={20} strokeWidth={1.2} />
                Delete file
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      }),
    },
  ];

  const [selectedDocument, setSelectedDocument] = React.useState<Document[]>(
    []
  );
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [checkedList, setCheckedList] = React.useState(
    columns.map((item) => item.key).filter((key) => key !== undefined)
  );
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [data, setData] = React.useState<Document[]>([]);

  const searchInput = React.useRef<InputRef>(null);

  const options = columns
    .map(({ key, title }) => ({
      label: title || "Unnamed Column",
      value: key,
    }))
    .filter((option) => option.value !== undefined);

  const newColumns = columns?.filter((item) =>
    checkedList?.includes(item?.key?.toString() as string)
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0] as string);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const rowSelection: TableRowSelection<Document> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 !== 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            return index % 2 === 0;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  // pagination
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);

  // model
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showEditFile, setShowEditFile] = React.useState(false);
  const [fileList, setFileList] = React.useState<UploadProps["fileList"]>([]);
  const [showDeleteMultipleDialog, setShowDeleteMultipleDialog] =
    React.useState(false);

  // logic for fetch workflowid from url
  const { pathname } = useLocation();
  const workflow_id = pathname.split("/").pop()?.split("-").splice(1).join("-");
  const workflow_name = pathname
    .split("/")
    .pop()
    ?.split("-")
    .splice(0, 1)
    .join("-");

  // hooks
  const { getWorkflowFiles } = useNewWorkflow();
  const { uploadFileProps } = useUploadFile({
    document_type: "invoice",
    workflow_id: workflow_id as string,
    workflow_name: workflow_name as string,
    fileList,
    setFileList,
  });
  // const { enqueueSnackbar } = useSnackbar();
  // const { response, isConnected, registerConnection } = useWebSocket();
  const { deleteFileHandler, deleteFilesHandler } = useDeleteFile();

  const {
    data: workflowFiles,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["workflowFiles", workflow_id, page, pageSize],
    queryFn: () =>
      getWorkflowFiles({
        workflowId: workflow_id as string,
        page,
        limit: pageSize,
      }),
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["delete-file"],
    mutationFn: deleteFileHandler,
    onSuccess: () => {
      refetch();
      setShowDeleteDialog(false);
    },
  });

  const { mutateAsync: deleteMultipleFiles, isPending: isPendingMultiple } =
    useMutation({
      mutationKey: ["delete-files"],
      mutationFn: deleteFilesHandler,
      onSuccess: () => {
        refetch();
        setShowDeleteMultipleDialog(false);
      },
    });

  React.useEffect(() => {
    if (workflowFiles) {
      setData(workflowFiles.data.files);
    }
  }, [workflowFiles]);

  // React.useEffect(() => {
  //   if (workflow_id) {
  //     registerConnection(workflow_id);
  //   }
  // }, [workflow_id, registerConnection]);

  // React.useEffect(() => {
  //   if (
  //     (isConnected && response?.event === NotificationEvent.NOTIFICATION) ||
  //     response?.event === NotificationEvent.MAIL
  //   ) {
  //     enqueueSnackbar({
  //       message: response.payload.data?.message,
  //       variant: response.payload.data?.type as "success" | "error" | "info",
  //       preventDuplicate: true,
  //       autoHideDuration: 5000,
  //       anchorOrigin: {
  //         horizontal: "right",
  //         vertical: "top",
  //       },
  //       hideIconVariant: false,
  //     });
  //   }
  //   if (isConnected && response && fileList) {
  //     refetch();
  //   }
  // }, [response, isConnected, refetch, enqueueSnackbar, fileList]);

  const handleDeleteFile = (documentId: string) => {
    mutateAsync(documentId);
  };

  const handleMultipleDeleteFile = (documentIds: string[]) => {
    deleteMultipleFiles(documentIds);
  };

  const handleEditSheetOpener = (document: Document) => {
    setDocumentInfo(document);
    setShowEditFile(true);
  };

  const [tableHeight, setTableHeight] = React.useState<number>(620);
  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 200;
    const footerHeight = 50;
    const availableHeight = windowHeight - headerHeight - footerHeight;
    setTableHeight(availableHeight > 400 ? availableHeight : 400);
  };

  React.useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  return (
    <div className="h-[calc(100vh-86px)] w-full ml-0 relative">
      {/* modals */}
      <div className="px-4 mt-4">
        <DeleteScanData
          deleteLoading={isPending}
          documentInfo={documentInfo as Document}
          handleDeleteFile={handleDeleteFile}
          setShowDeleteDialog={setShowDeleteDialog}
          showDeleteDialog={showDeleteDialog}
          key={100}
        />
        <EditDocument
          setShowEditFile={setShowEditFile}
          showEditFile={showEditFile}
          documentInfo={documentInfo as Document}
          refetch={refetch}
        />
        <DeleteMultipleFiles
          deleteLoading={isPendingMultiple}
          documentInfo={selectedDocument as Document[]}
          handleDeleteFile={handleMultipleDeleteFile}
          setShowDeleteMultipleDialog={setShowDeleteMultipleDialog}
          showDeleteDialog={showDeleteMultipleDialog}
        />
        {hoveredRecord && <Previewer file_url={hoveredRecord as string} />}
        <div className="mb-4 flex items-center justify-between gap-[10px]">
          <div>
            <Upload {...uploadFileProps}>
              <Button
                type="primary"
                icon={<UploadCloud strokeWidth={1.2} size={20} />}
              >
                Scan new invoice
              </Button>
            </Upload>
          </div>
          <div className="flex items-center gap-[10px]">
            <Link
              to={`/configurations/${pathname
                .split("/")
                .pop()
                ?.split("-")
                .splice(1)
                .join("-")}#schema`}
            >
              <Button
                type="dashed"
                icon={<Settings2 strokeWidth={1.2} size={20} />}
              >
                <span>Configuration</span>
              </Button>
            </Link>
            <DownloadReportButton />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  type="dashed"
                  icon={<ChevronDown size={20} strokeWidth={1.2} />}
                >
                  Select Columns
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="font-popins">
                {options.map((option) => (
                  <DropdownMenuItem key={option.value}>
                    <Checkbox
                      checked={checkedList.includes(option?.value as string)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedList((prev) => [
                            ...prev,
                            option.value as string,
                          ]);
                        } else {
                          setCheckedList((prev) =>
                            prev.filter((item) => item !== option.value)
                          );
                        }
                      }}
                    >
                      {option?.value as string}
                    </Checkbox>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <Table
          rowKey={(record) => record?.documentId as string}
          loading={isLoading}
          className="border rounded-md font-popins "
          columns={newColumns}
          rowSelection={rowSelection}
          dataSource={data}
          scroll={{ x: 1300, y: tableHeight }}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.file_name}</p>
            ),
            rowExpandable: (record) => record.file_name !== "Not Expandable",
          }}
        />
        <div className="flex items-center justify-end bg-[#fbfcfe] dark:bg-gray-800 py-[11.5px] px-[25px] absolute right-0 bottom-0 border-t w-full">
          <Pagination
            defaultPageSize={pageSize}
            total={workflowFiles?.data.pagination.total ?? 0}
            current={page}
            onChange={(page) => setPage(page)}
            showSizeChanger={true}
            onShowSizeChange={(current, size) => {
              setPageSize(size);
              setPage(current);
            }}
            showQuickJumper={true}
            showTotal={(total, range) => (
              <span className="dark:text-white">
                Showing {range[0]}-{range[1]} of {total} invoices
              </span>
            )}
            showLessItems={true}
            showTitle={true}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkflowDataTable;
