import { useEffect, useState, useCallback, useRef } from "react";
import { io, Socket } from "socket.io-client";

import { Message } from "@/types/email.type";

export enum WebSocketEvent {
  SUBSCRIBE = "subscribe",
  REGISTER = "register",
  SUCCEEDED = "succeeded",
  FAIL = "fail",
}

export enum NotificationEvent {
  MAIL = "mail",
  NOTIFICATION = "notification",
  NEW_EMAIL = "new_emails",
}

const useSocket = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [notifications, setNotifications] = useState<Message[]>([]);
  const pendingMessages = useRef<{ event: string; payload: unknown }[]>([]); // Queue for pending messages

  useEffect(() => {
    const socketInstance = io(import.meta.env.VITE_WS_URL, {
      withCredentials: false, // Enable if the server requires cookies/auth headers
      transports: ["websocket"],
      path: "/ws",
    });

    setSocket(socketInstance);

    socketInstance.on("connect", () => {
      setIsConnected(true);

      // Send any pending messages when the socket connects
      pendingMessages.current.forEach(({ event, payload }) => {
        socketInstance.emit(event, payload);
      });
      pendingMessages.current = [];
    });

    socketInstance.on("disconnect", () => {
      setIsConnected(false);
    });

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  const sendMessage = useCallback(
    (event: WebSocketEvent, payload: unknown) => {
      if (socket && isConnected) {
        socket.emit(event, payload);
      } else {
        console.warn("Socket is not connected. Queuing message.");
        pendingMessages.current.push({ event, payload }); // Queue the message
      }
    },
    [socket, isConnected]
  );

  const subscribeToNotifications = useCallback(() => {
    if (socket) {
      socket.on(NotificationEvent.MAIL, (data) => {
        setNotifications((prev) => [...prev, data]);
      });

      socket.on(NotificationEvent.NOTIFICATION, (data) => {
        setNotifications((prev) => [...prev, data]);
      });

      socket.on(NotificationEvent.NEW_EMAIL, (data) => {
        console.log("New email received", data);
        setNotifications((prev) => [...prev, data]);
      });
    }
  }, [socket]);

  const registerWorkflow = useCallback(
    (workflowId: string) => {
      sendMessage(WebSocketEvent.REGISTER, { workflowId });
    },
    [sendMessage]
  );

  const subscribeUser = useCallback(
    (userId: string) => {
      sendMessage(WebSocketEvent.SUBSCRIBE, { userId });
    },
    [sendMessage]
  );

  return {
    socket,
    isConnected,
    sendMessage,
    subscribeToNotifications,
    registerWorkflow,
    subscribeUser,
    notifications,
    setNotifications,
  };
};

export default useSocket;
