/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LottiePlayer from "lottie-react";
import { Skeleton } from "antd";
import { useInView } from "react-intersection-observer";

import { useInfiniteQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import ListHeader from "../components/ListHeader";
import ScrollToActions from "./Scroller";

import emptyIboxLottie from "@/assets/lottis/empty-inbox.json";

import { useUser } from "@/providers/userProvider";
import { useMyMail } from "@/providers/myMailContext";
import { useThread } from "@/providers/thredContext";
import { useLabelSelect } from "@/providers/labelSelectContext";
import { useFilters } from "@/providers/FiltersContext";

import { gmailApi } from "../api/gmail";
import { Page, Label, Message } from "@/types/email.type";

import ConnectState from "./ConnectState";

import { useScroller } from "../hooks/useScroller";
import MessageUI from "./MessageUI";
import useSocket from "@/hooks/useWebsocket";

interface ListPros {
  setGlobalSearch: React.Dispatch<React.SetStateAction<string>>;
  labels: Label[] | undefined;
}

export enum SelectedMessage {
  all = "all",
  unread = "unread",
  read = "read",
  none = "none",
}

const List: React.FC<ListPros> = ({ labels }) => {
  const selectedThreadRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView();

  const { getMessageList } = gmailApi();
  const { user } = useUser();
  const { setPage, page } = useMyMail();
  const { setThread, thread } = useThread();
  const { labels: selectedLabels } = useLabelSelect();
  const { pathname } = useLocation();
  const { inbox_type } = useParams();
  const { selectedPriority, selectedStatus } = useFilters();
  const { scrollToThread, scrollToTop } = useScroller(
    selectedThreadRef,
    listRef
  );

  const thread_id = pathname.split("/")[3];

  const [pageQuery, setPageQuery] = React.useState<string>("INBOX");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [selectedMessage, setSelectedMessage] =
    React.useState<{
      type: SelectedMessage
    }>();

  const {
    isConnected,
    subscribeToNotifications,
    subscribeUser,
    notifications,
    setNotifications,
  } = useSocket();

  React.useEffect(() => {
    if (isConnected && user?.inbox?.inbox_account_email) {
      subscribeUser(user.userId);
      subscribeToNotifications();
    }
  }, [isConnected, subscribeToNotifications, user]);

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
  } = useInfiniteQuery<Page, Error>({
    queryKey: ["getAllMails", pageQuery],
    queryFn: async ({ pageParam = null }) => {
      const response = await getMessageList(
        pageParam as string | null,
        pageQuery
      );
      return response.data as Page;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.messages.nextPageToken) {
        return lastPage.messages.nextPageToken ?? null;
      }
      return null;
    },
    initialPageParam: null,
    enabled: !!user?.inbox?.inbox_account_email,
    // staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

  // Infinite scroll fetching when inView
  React.useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, isFetchingNextPage, hasNextPage]);

  React.useEffect(() => {
    if (thread_id) {
      setThread({
        thredId: thread_id,
      });
    }
  }, [thread_id, setThread]);

  React.useEffect(() => {
    if (data) {
      const allMessages = Object.values(
        data.pages
          .flatMap((page) => page.messages.messages)
          .reduce((acc, mail) => {
            if (!acc[mail.threadId]) {
              acc[mail.threadId] = mail;
            }
            return acc;
          }, {} as Record<string, Message>)
      );
      setPage({
        lastSyncDate: data.pages[0].lastSyncDate,
        messages: {
          messages: allMessages,
          nextPageToken: data.pages[0].messages.nextPageToken,
        },
      });
    }
  }, [data, setPage]);

  // change the pageQuery based on the inbox_type
  React.useEffect(() => {
    const upperCaseInboxType = inbox_type?.toLocaleUpperCase();
    if (
      ["MY-MAILS", "ALL-MAILS", "UNASSIGNED"].includes(
        upperCaseInboxType as string
      )
    ) {
      setPageQuery("INBOX");
    } else if (upperCaseInboxType === "SENT") {
      setPageQuery("SENT");
    } else if (upperCaseInboxType === "SPAMS") {
      setPageQuery("SPAM");
    }
  }, [inbox_type]);

  const searchFilter = React.useCallback(
    (searchText: string) => {
      setSearchTerm(searchText);
    },
    [setSearchTerm]
  );

  // filter messages based on inbox_type
  const filterMessages = React.useMemo(() => {
    if (!page.messages.messages) return [];

    const inboxFilteredMessages = (() => {
      const inboxType = inbox_type?.toLowerCase();
      if (!inboxType || ["sent", "spams", "all-mails"].includes(inboxType)) {
        return [...page.messages.messages];
      }

      const filterByAssignees =
        inboxType === "my-mails"
          ? (mail: Message) =>
              (mail.assignees?.length || 0) > 0 &&
              mail.assignees.some((assignee) => assignee.email === user?.email)
          : (mail: Message) => (mail.assignees?.length || 0) === 0;

      return page.messages.messages.filter(filterByAssignees);
    })().sort(
      (a, b) =>
        new Date(b.internalDate).getTime() - new Date(a.internalDate).getTime()
    );

    const labelFilteredMessages = (() => {
      const selectedLabelNames = selectedLabels
        .filter((label) => label.isSelected)
        .map((label) => label.name.toLowerCase());

      if (selectedLabelNames.length === 0) return inboxFilteredMessages;

      return inboxFilteredMessages.filter((mail) =>
        selectedLabelNames.includes(mail.label?.toLowerCase() ?? "")
      );
    })();

    const priorityAndStatusFilteredMessages = labelFilteredMessages.filter(
      (mail) => {
        const matchesPriority =
          !selectedPriority.length ||
          selectedPriority.includes(mail.priority_level);

        const matchesStatus =
          !selectedStatus.length ||
          selectedStatus.some(
            (status) => mail.status?.toUpperCase() === status.toUpperCase()
          );

        return matchesPriority && matchesStatus;
      }
    );

    const searchFilteredMessages = priorityAndStatusFilteredMessages.filter(
      (mail) => {
        if (!searchTerm.trim()) return true;
        const searchLowerCase = searchTerm.toLowerCase();

        const sender = mail.payload.headers
          .find((header) => header.name === "From")
          ?.value?.split("<")[0]
          ?.replace(/"/g, "")
          ?.toLowerCase();
        const subject = mail?.payload.headers
          .find(
            (header: { name: string; value: string }) =>
              header.name === "Subject"
          )
          ?.value?.toLowerCase();
        const snippet = mail.snippet?.toLowerCase();

        return (
          sender?.includes(searchLowerCase) ||
          subject?.includes(searchLowerCase) ||
          snippet?.includes(searchLowerCase)
        );
      }
    );

    const seenMessages = searchFilteredMessages.map((mail) => {
      if (thread && mail.threadId === thread.thredId) {
        return {
          ...mail,
          labelIds: [
            ...mail.labelIds.filter((label) => label !== "UNREAD"),
            ...(mail.labelIds.includes("READ") ? [] : ["READ"]),
          ],
        };
      }
      return mail;
    });

    const notificationsMap = notifications
      .flat()
      .reduce((acc, notification) => {
        if (notification.threadId) {
          return { ...acc, [notification.threadId]: notification };
        }
        return acc;
      }, {} as Record<string, Message>);

    const updatedMessagesMap = seenMessages.reduce((acc, message) => {
      if (message.threadId) {
        acc[message.threadId] = acc[message.threadId] || { ...message };
      }
      return acc;
    }, {} as Record<string, Message>);

    Object.values(notificationsMap).forEach((notification) => {
      const existingMessage = updatedMessagesMap[notification.threadId];
      if (existingMessage) {
        updatedMessagesMap[notification.threadId] = {
          ...notification,
          threadCount: (existingMessage.threadCount || 0) + 1,
        };
      } else {
        updatedMessagesMap[notification.threadId] = {
          ...notification,
        };
      }
    });

    // Step 7: Convert Map to Sorted Array
    const finalMessages = Object.values(updatedMessagesMap).sort((a, b) => {
      const isANotification = !!notificationsMap[a.threadId];
      const isBNotification = !!notificationsMap[b.threadId];
      if (isANotification && !isBNotification) return -1;
      if (!isANotification && isBNotification) return 1;
      return (
        new Date(b.internalDate).getTime() - new Date(a.internalDate).getTime()
      );
    });
    return finalMessages;
  }, [
    notifications,
    page.messages.messages,
    inbox_type,
    selectedPriority,
    selectedStatus,
    selectedLabels,
    thread,
    searchTerm,
  ]);

  React.useEffect(() => {
    if (notifications.length > 0) {
      // Scroll to the top
      listRef.current?.scrollTo({ top: 0, behavior: "smooth" });
      setNotifications([]);
    }
  }, [notifications]);

  return (
    <div className="border-r">
      <div>
        <ListHeader
          loading={isLoading}
          count={filterMessages.length || 0}
          lastSyncDate={
            (page?.lastSyncDate as Date) || data?.pages[0].lastSyncDate
          }
          searchFilter={searchFilter}
          setSelectedMessage={setSelectedMessage}
          selectedMessage={selectedMessage}
        />
      </div>
      <div className="">
        <div
          ref={listRef}
          className="h-[calc(100vh-100px)] overflow-scroll py-[5px] overflow-x-hidden relative group scrollbar-visible"
        >
          {!user?.inbox?.inbox_account_email && <ConnectState />}
          {status === "pending" && isLoading && (
            <div className="p-4 mt-[-15px]">
              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton avatar key={index} active title className="p-4" />
              ))}
            </div>
          )}
          {status === "success" &&
          filterMessages.length === 0 &&
          !isLoading &&
          !isFetchingNextPage ? (
            <div>
              <div className="flex flex-col items-center justify-center h-full gap-0">
                <LottiePlayer
                  animationData={emptyIboxLottie}
                  loop={false}
                  autoplay
                  className="mx-auto w-full pl-10 h-[400px]"
                />
                <p className="text-center">No results found</p>
              </div>
            </div>
          ) : (
            <MessageUI
              messages={filterMessages}
              labels={labels}
              searchTerm={searchTerm}
              selectedThreadRef={selectedThreadRef}
              setThread={setThread}
              thread={thread}
              key={inbox_type}
              selectedMessage={selectedMessage}
              setSelectedMessage={setSelectedMessage}
            />
          )}
          {status === "success" && (
            <div ref={ref}>
              {isFetchingNextPage && (
                <div className="w-full flex items-center justify-center px-[40px] py-[10px]">
                  <Skeleton avatar active title />
                </div>
              )}
            </div>
          )}

          <div className="fixed hover:flex hidden z-50 w-[490px] backdrop-blur-sm items-center bottom-[0px] group-hover:block py-[10px]">
            <ScrollToActions
              scrollToThread={scrollToThread}
              scrollToTop={scrollToTop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
