import { Key, useState } from "react";
import { Table, DatePicker, Card, Row, Col, Tag } from "antd";
import {
  DollarOutlined,
  FieldTimeOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { ColumnType } from "antd/es/table";

dayjs.extend(isBetween);

import { ReactComponent as ROI } from "@/assets/svgs/roi.svg";
import { ReactComponent as Transaction } from "@/assets/svgs/transactions.svg";
import { ReactComponent as LastPayment } from "@/assets/svgs/payment.svg";

interface Payment {
  id: number;
  date: string;
  amount: number;
  status: string;
  method: string;
  timeStamps: string;
  transactionId?: string;
}

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// Mock data for the payment history
const paymentData: Payment[] = [];

export default function PaymentHistory() {
  const [searchRange, setSearchRange] = useState<RangeValue>(null);
  // Filtered data based on selected date range
  const filteredData = paymentData.filter((payment) => {
    const paymentDate = dayjs(payment.date);
    if (!searchRange || !searchRange[0] || !searchRange[1]) return true;
    return paymentDate.isBetween(searchRange[0], searchRange[1], "day", "[]");
  });

  const columns: ColumnType<Payment>[] = [
    {
      title: "Date",
      dataIndex: "timeStamps",
      key: "timeStamps",
      sorter: (a: Payment, b: Payment) =>
        new Date(a.timeStamps).getTime() - new Date(b.timeStamps).getTime(),
      render: (text: string) =>
        new Date(text).toLocaleDateString("us-en", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "UTC",
          hour12: true,
          hourCycle: "h23",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a: Payment, b: Payment) => a.amount - b.amount,
      render: (amount: number) => `$${amount.toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag color={status === "Paid" ? "green" : "red"}>{status}</Tag>
      ),
      filters: [
        { text: "Paid", value: "Paid" },
        { text: "Fail", value: "Fail" },
      ],
      onFilter: (value: boolean | Key, record: Payment) =>
        record.status === (value as Key),
    },
    {
      title: "Payment Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
  ];

  const totalAmount = filteredData.reduce((sum, item) => sum + item.amount, 0);

  return (
    <div className="px-2">
      <Row gutter={16} className="mb-6">
        <Col span={8}>
          <Card>
            <Statistic
              title="Your ROI with Wingg"
              value={`${(totalAmount * 2.5).toFixed(2)}`}
              prefix={<DollarOutlined className="text-gray-600" />}
              bgIcon={<ROI className="w-[50px] h-[50px]" />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Number of Transactions"
              value={filteredData.length}
              prefix={<FieldTimeOutlined className="text-gray-600" />}
              bgIcon={<Transaction className="w-[50px] h-[50px]" />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Last Payment Date"
              value={
                filteredData.length > 0
                  ? new Date(filteredData[0].date).toLocaleDateString()
                  : "N/A"
              }
              prefix={<CalendarOutlined className="text-gray-600" />}
              bgIcon={<LastPayment className="w-[50px] h-[50px]" />}
            />
          </Card>
        </Col>
      </Row>

      <div className="flex items-start justify-between">
        <h1 className="text-xl font-semibold text-gray-700">Payment history</h1>
        <DatePicker.RangePicker
          onChange={(dates) => setSearchRange(dates)}
          className="mb-6"
        />
      </div>

      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        className="overflow-scroll"
        pagination={{ pageSize: 8 }}
        sticky
        virtual
        tailor
      />
    </div>
  );
}

interface StatisticProps {
  title: string;
  value: string | number;
  prefix?: React.ReactNode;
  bgIcon?: React.ReactNode;
}

const Statistic: React.FC<StatisticProps> = ({
  title,
  value,
  prefix,
  bgIcon,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <p className="text-2xl font-semibold text-gray-700 mt-1">
          {prefix}
          <span className="ml-2">{value}</span>
        </p>
      </div>
      {bgIcon}
    </div>
  );
};
