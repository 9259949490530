import { CheckCircle2 } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"

const plans = [
  {
    name: "Basic",
    price: "$9.99",
    description: "Perfect for individuals and small teams",
    features: [
      "Feature 1",
      "Feature 2",
      "Feature 3",
    ],
  },
  {
    name: "Pro",
    price: "$29.99",
    description: "Ideal for growing businesses",
    features: [
      "All Basic features",
      "Feature 4",
      "Feature 5",
      "Feature 6",
    ],
    popular: true,
  },
  {
    name: "Enterprise",
    price: "$99.99",
    description: "For large-scale operations",
    features: [
      "All Pro features",
      "Feature 7",
      "Feature 8",
      "Feature 9",
      "Feature 10",
    ],
  },
]

export default function PricingPage() {
  return (
    <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Choose the Right Plan for You
          </h1>
          <p className="mt-5 text-xl text-gray-500">
            Simple, transparent pricing that grows with you. Try any plan free for 30 days.
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:grid-cols-3">
          {plans.map((plan) => (
            <Card key={plan.name} className={`flex flex-col justify-between ${plan.popular ? 'border-blue-500 border-2' : ''}`}>
              {plan.popular && (
                <div className="absolute top-0 right-0 px-3 py-1 bg-blue-500 text-white text-sm font-semibold rounded-bl">
                  Popular
                </div>
              )}
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">{plan.name}</CardTitle>
                <CardDescription>{plan.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="text-4xl font-bold mb-4">{plan.price}<span className="text-lg font-normal text-gray-500">/month</span></div>
                <ul className="space-y-2">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Button className="w-full" variant={plan.popular ? "default" : "outline"}>
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

