import Highlighter from "react-highlight-words";
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Paperclip, Image, Video, Shapes } from "lucide-react";
import { Badge, Tooltip, Checkbox } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import defaultProfileImage from "@/assets/user-avatar.png";

import { ReactComponent as GoogleIcon } from "@/assets/svgs/googleIcon.svg";
import { ReactComponent as CheckReadIcon } from "@/assets/svgs/check-read.svg";
import { ReactComponent as LockIcon } from "@/assets/svgs/lock.svg";
import { ReactComponent as PendingIcon } from "@/assets/svgs/clock-pending.svg";

import { genrateInitials } from "@/helper/genrateInitials";
import { convertDateToTime } from "@/helper/timAgo";
import { Label, Message } from "@/types/email.type";
import { SelectedMessage } from "./List";

import { useUser } from "@/providers/userProvider";

interface MessageUIProps {
  messages: Message[];
  thread: { thredId: string | null };
  selectedThreadRef: React.RefObject<HTMLDivElement>;
  setThread: React.Dispatch<
    React.SetStateAction<{
      thredId: string | null;
    }>
  >;
  searchTerm: string;
  labels: Label[] | undefined;
  selectedMessage: { type: SelectedMessage } | undefined;
  setSelectedMessage: (value: { type: SelectedMessage } | undefined) => void;
}

const MessageUI: React.FC<MessageUIProps> = ({
  messages,
  thread,
  selectedThreadRef,
  setThread,
  searchTerm,
  labels,
  selectedMessage,
  setSelectedMessage,
}) => {
  const navigate = useNavigate();
  const { inbox_type } = useParams();
  const { user } = useUser();

  return (
    <>
      {messages.map((mail, index) => (
        <div
          key={mail?.threadId}
          ref={mail?.threadId === thread?.thredId ? selectedThreadRef : null}
          className={
            mail?.threadId === thread?.thredId
              ? "px-[10px] w-full py-2 flex items-center gap-[10px] hover:shadow-lg border border-dashed border-[#fb6c3c]/60"
              : "px-[10px] w-full py-2 flex items-center gap-[10px] hover:shadow-lg border-b hover:bg-[#fad3c6]/10 z-10"
          }
          style={{
            backgroundColor: mail?.labelIds?.find(
              (label: string) => label === "UNREAD"
            )
              ? "#f0eeed79"
              : "#fff",
          }}
        >
          {user?.inbox_rights.delete_right && (
            <Checkbox
              checked={
                selectedMessage?.type === SelectedMessage.read
                  ? mail?.labelIds?.find((label: string) => label === "UNREAD")
                    ? false
                    : true
                  : selectedMessage?.type === SelectedMessage.unread
                  ? mail?.labelIds?.find((label: string) => label === "UNREAD")
                    ? true
                    : false
                  : selectedMessage?.type === SelectedMessage.all
                  ? true
                  : false
              }
              className="flex items-center justify-center"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedMessage({
                    type: SelectedMessage.all,
                  });
                } else {
                  setSelectedMessage({
                    type: SelectedMessage.none,
                  });
                }
              }}
            />
          )}
          <div
            className="flex items-center gap-[10px]"
            onClick={() => {
              navigate(`/inbox/${inbox_type}/${mail?.threadId}`);
              setThread({
                thredId: mail?.threadId,
              });
            }}
          >
            <div className="flex gap-[5px]">
              <Avatar className="h-[40px] w-[40px] text-[20px]">
                <AvatarImage
                  src={
                    genrateInitials(
                      mail?.payload.headers
                        .find(
                          (header: { name: string; value: string }) =>
                            header.name === "From"
                        )
                        ?.value.split("<")[0]
                        ?.replace(/"/g, "")
                        ?.replace(/'/g, "")
                        ?.charAt(0) as string
                    ) as string
                  }
                />
                <AvatarFallback>
                  <span>
                    {mail?.payload.headers &&
                      mail.payload?.headers
                        ?.find(
                          (header: { name: string; value: string }) =>
                            header.name === "From"
                        )
                        ?.value.split("<")[0]
                        ?.replace(/"/g, "")
                        ?.replace(/'/g, "")
                        .charAt(0)}
                  </span>
                </AvatarFallback>
              </Avatar>
            </div>
            <div
              className={cn(
                "flex flex-col gap-[3px] max-w-[425px]",
                user?.inbox_rights.delete_right && "max-w-[400px]"
              )}
            >
              <div className="flex items-center justify-between ">
                <h4 className="text-[13px] font-semibold flex items-center gap-1">
                  {mail?.labelIds?.find(
                    (label: string) => label === "UNREAD"
                  ) && (
                    <Badge
                      color="red"
                      status="processing"
                      classNames={{ indicator: "mr-1" }}
                    />
                  )}
                  <Highlighter
                    highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                    searchWords={[searchTerm]}
                    autoEscape={true}
                    textToHighlight={
                      mail?.payload?.headers
                        ?.find(
                          (header: { name: string; value: string }) =>
                            header.name === "From"
                        )
                        ?.value?.split("<")[0]
                        ?.replace(/"/g, "") || ""
                    }
                  />
                  {mail?.threadCount > 1 && (
                    <Badge
                      status="success"
                      color="#a1a1a1"
                      classNames={{
                        root: "!rounded-md",
                        indicator: "!rounded-md",
                      }}
                      size="small"
                      rootClassName="text-sm"
                      count={mail.threadCount}
                    />
                  )}
                  {mail?.status === "RESOLVED" && (
                    <CheckReadIcon className="w-[20px] h-[18px] mb-[2px]  fill-green-400 stroke-green-500" />
                  )}
                  {mail?.status === "CLOSED" && (
                    <LockIcon className="w-[20px] h-[14px] mb-[2px] fill-blue-100" />
                  )}
                  {mail.status === "PENDING" && (
                    <PendingIcon className="w-[20px] h-[14px] mb-[1px] fill-orange-100" />
                  )}
                </h4>
                <span className="text-sm text-gray-500">
                  {convertDateToTime(mail?.internalDate)}
                </span>
              </div>
              <p className="text-sm text-gray-700">
                <Highlighter
                  highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                  searchWords={[searchTerm]}
                  autoEscape={true}
                  textToHighlight={
                    mail?.payload.headers.find(
                      (header: { name: string; value: string }) =>
                        header.name === "Subject"
                    )?.value || ""
                  }
                />
              </p>
              <p className="text-sm text-gray-500 text-ellipsis whitespace-nowrap overflow-hidden">
                <Highlighter
                  highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                  searchWords={[searchTerm]}
                  autoEscape={true}
                  textToHighlight={mail?.snippet || ""}
                />
              </p>
              <div className="flex items-center justify-between mt-[5px]">
                {mail?.attachments?.length && mail?.attachments.length > 0 ? (
                  <div className="flex gap-2">
                    {mail?.attachments.filter(
                      (att: { type: string }) =>
                        att.type === "pdf" || att.type === "docx"
                    ).length > 0 && (
                      <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                        <Paperclip size={16} />
                        <span className="text-sm text-gray-500">
                          {
                            mail.attachments.filter(
                              (att: { type: string }) =>
                                att.type === "pdf" || att.type === "docx"
                            ).length
                          }
                        </span>
                      </div>
                    )}
                    {mail?.attachments.filter(
                      (att: { type: string }) => att.type === "image"
                    ).length > 0 && (
                      <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                        <Image size={16} />

                        <span className="text-sm text-gray-500">
                          {
                            mail?.attachments.filter(
                              (att: { type: string }) =>
                                att.type === "image" || att.type === "docx"
                            ).length
                          }
                        </span>
                      </div>
                    )}
                    {mail?.attachments.filter(
                      (att: { type: string }) => att.type === "video"
                    ).length > 0 && (
                      <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                        <Video size={16} />
                        <span className="text-sm text-gray-500">
                          {
                            mail?.attachments.filter(
                              (att: { type: string }) => att.type === "video"
                            ).length
                          }
                        </span>
                      </div>
                    )}
                    {mail?.attachments.filter(
                      (att: { type: string }) => att.type === "other"
                    ).length > 0 && (
                      <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                        <Shapes size={16} />
                        <span className="text-sm text-gray-500">
                          {
                            mail.attachments.filter(
                              (att: { type: string }) => att.type === "other"
                            ).length
                          }
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div />
                )}
                <div className="flex items-center gap-2">
                  {mail?.priority_level !== null && (
                    <div
                      className={`text-xs flex items-center gap-1 capitalize px-[6px] py-1 rounded-md ${
                        mail?.priority_level.split(" ")[0] === "P1"
                          ? "bg-red-500 text-white"
                          : mail?.priority_level.split(" ")[0] === "P2"
                          ? "bg-yellow-500"
                          : "bg-blue-500 text-gray-100"
                      }`}
                    >
                      {mail?.priority_level.split(" ")[0]}
                    </div>
                  )}
                  {mail.label === "OTHER" ? null : (
                    <span
                      key={mail?.id}
                      className={`text-sm flex items-center gap-[5px] text-gray-700 capitalize px-[10px] py-1 rounded-md`}
                      style={{
                        backgroundColor: labels?.find(
                          (label) =>
                            label.name?.toUpperCase() ===
                            mail.label?.toUpperCase()
                        )?.secondaray_color,
                      }}
                    >
                      {["G-PROMOTIONS", "G-SOCIAL", "G-FORUMS"].filter(
                        (label) => label === mail.label?.toUpperCase()
                      ).length > 0 ? (
                        <GoogleIcon className="h-[13px] w-[13px] bg-blend-overlay" />
                      ) : (
                        <div
                          key={mail?.id + index}
                          className={"h-[12px] w-[12px] rounded-md border-2"}
                          style={{
                            borderColor:
                              labels?.find(
                                (label) =>
                                  label.name?.toUpperCase() ===
                                  mail.label?.toUpperCase()
                              )?.color || "#8b8b8b",
                          }}
                        />
                      )}
                      {mail.label === "OTHER" ? null : mail.label}
                    </span>
                  )}
                  {mail?.assignees?.length > 0 && (
                    <div className="flex gap-1">
                      {mail.assignees?.map(
                        (assignee: {
                          name: string;
                          email: string;
                          profile_image: string;
                        }) => (
                          <Tooltip
                            color="white"
                            className="z-50"
                            rootClassName="!min-w-[280px]"
                            title={
                              <div className="flex items-start gap-[7px] z-50">
                                <Avatar className="rounded h-[22px] w-[22px]">
                                  <img
                                    src={assignee?.profile_image}
                                    className="object-cover w-[100px] h-[100px] rounded-lg"
                                  />
                                  <AvatarFallback>
                                    <AvatarImage
                                      className="object-cover p-1 rounded-lg"
                                      src={defaultProfileImage}
                                    />
                                  </AvatarFallback>
                                </Avatar>
                                <div className="w-[200px] overflow-hidden text-ellipsis">
                                  <p className="text-gray-700 font-semibold">
                                    {assignee.name}
                                  </p>
                                  <p className="text-sm text-gray-600">
                                    {assignee.email}
                                  </p>
                                </div>
                              </div>
                            }
                          >
                            <Avatar className="rounded h-[22px] w-[22px]">
                              <img
                                src={assignee?.profile_image}
                                className="object-center object-cover"
                              />
                              <AvatarFallback>
                                <AvatarImage
                                  className="object-cover p-1 rounded-lg"
                                  src={defaultProfileImage}
                                />
                              </AvatarFallback>
                            </Avatar>
                          </Tooltip>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MessageUI;
