import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { User } from "../hooks/types";

import { useUsers } from "../hooks/useUsers";

interface Props {
  showDeleteDialog: boolean;
  onClose: () => void;
  refetch: () => void;
  userDetails: User | null;
}

const RemoveUser = ({
  showDeleteDialog,
  onClose,
  userDetails,
  refetch,
}: Props) => {
  const { removeUser } = useUsers();
  const { enqueueSnackbar } = useSnackbar();

  const { isPending, mutateAsync } = useMutation({
    mutationKey: ["removeUser"],
    mutationFn: removeUser,
    onSuccess: (data) => {
      onClose();
      refetch();
      return enqueueSnackbar(data.data.message);
    },
    onError: (error: AxiosError) => {
      return enqueueSnackbar(
        (error.response?.data as { message: string }).message,
        { variant: "error" }
      );
    },
  });

  return (
    <AlertDialog open={showDeleteDialog} onOpenChange={onClose}>
      <AlertDialogContent className="min-w-[560px] w-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className="text-red-500">
            <span className="dark:text-gray-200 text-gray-700">
              {userDetails?.name}
            </span>{" "}
            will be permanently deleted. This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex">
          <AlertDialogCancel className="w-[100px]" onClick={onClose}>
            Cancel
          </AlertDialogCancel>
          <Button
            className="bg-red-500 hover:bg-red-600 w-[120px] disabled:bg-red-500"
            onClick={() => {
              mutateAsync({ user_id: userDetails?.userId as string });
            }}
            loading={isPending}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RemoveUser;
