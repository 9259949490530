import React from "react";
import { Flex, Select, Tooltip } from "antd";
import Confetti from "react-confetti";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { ReactComponent as GmailIcon } from "@/assets/svgs/gmail.svg";

import Text from "@/components/Text";

import { useUser } from "@/providers/userProvider";

const notConnectedMessage = "No Google account connected";

const Account = () => {
  const { user } = useUser();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const errorMessage = searchParams.get("error");
  const successMessage = searchParams.get("success");
  const [showConfetti, setShowConfetti] = React.useState(false);

  React.useEffect(() => {
    if (errorMessage) {
      if (errorMessage === "access_denied") {
        enqueueSnackbar("You have denied the access to your google account.", {
          variant: "error",
        });
        setTimeout(() => {
          navigate(window.location.pathname, { replace: true });
        }, 5200);
      } else enqueueSnackbar(atob(errorMessage), { variant: "error" });
      setTimeout(() => {
        navigate(window.location.pathname, { replace: true });
      }, 5200);
    }
    if (successMessage) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 4000);
      enqueueSnackbar(atob(successMessage));
      setTimeout(() => {
        navigate(window.location.pathname, { replace: true });
      }, 4000);
    }
  }, [errorMessage, successMessage, navigate]);

  return (
    <div className="bg-white w-full m-0 px-5 py-[15px] border-tl rounded-tl-xl">
      <Flex align="center" gap={5}>
        <GmailIcon className="h-[30px] w-[30px]" />
        <Text big>Google Account</Text>
      </Flex>
      <Select
        showAction={["focus"]}
        showSearch
        className="w-full mt-2"
        defaultValue={
          user?.inbox?.inbox_account_email
            ? user?.inbox?.inbox_account_email
            : notConnectedMessage
        }
        filterOption={(input, option) =>
          (option?.value as string)
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: (
              <Tooltip
                arrow={false}
                title={user?.inbox?.inbox_account_email || notConnectedMessage}
              >
                {user?.inbox?.inbox_account_email}
                {notConnectedMessage}
              </Tooltip>
            ),
            value: user?.inbox?.inbox_account_email
              ? user?.inbox?.inbox_account_email
              : notConnectedMessage,
          },
        ]}
      />
      {showConfetti && <Confetti />}
    </div>
  );
};

export default Account;
