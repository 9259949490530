import React from "react";
import { useParams } from "react-router-dom";
import { useMyMail } from "@/providers/myMailContext";
import { useQueryClient } from "@tanstack/react-query";
import { gmailApi } from "../api/gmail";
import { Message } from "@/types/email.type";

const useRefetchLatestEmail = () => {
  const queryClient = useQueryClient();
  const { syncMessageList } = gmailApi();
  const { setPage, page } = useMyMail();
  const [isSyncMail, setIsSyncMail] = React.useState(false);
  const { inbox_type } = useParams<{ inbox_type: string }>();

  // Helper function to sort messages
  const sortMessagesByDate = React.useCallback(
    (messages: Message[]) =>
      messages.sort(
        (a, b) =>
          new Date(b.internalDate).getTime() - new Date(a.internalDate).getTime()
      ),
    []
  );

  // Memoized logic for filtering messages based on `inbox_type`
  const filterMessagesByInboxType = React.useMemo(() => {
    return (messages: Message[]) => {
      switch (inbox_type?.toLowerCase()) {
        case "my-mails":
          return messages.filter((mail) => mail.assignees?.length > 0);
        case "sent":
          return messages.filter((mail) => mail.labelIds?.includes("SENT"));
        case "spams":
          return messages.filter((mail) => mail.labelIds?.includes("SPAM"));
        case "unassigned":
          return messages.filter((mail) => mail.assignees?.length === 0);
        default:
          return messages; // Default for "all-mails" or undefined cases
      }
    };
  }, [inbox_type]);

  // Memoized function to update the page state
  const updatePageWithMessages = React.useCallback(
    (newMessages: Message[], nextPageToken: string | null, lastSyncDate: Date) => {
      const currentMessages = page?.messages.messages || [];
      const updatedMessages = sortMessagesByDate([
        ...newMessages,
        ...currentMessages,
      ]);

      setPage((prev) => ({
        ...prev,
        messages: {
          messages: updatedMessages.sort((a,b) => new Date(a.internalDate) > new Date(b.internalDate) ? -1 : 1),
          nextPageToken,
        },
        lastSyncDate,
      }));
    },
    [page?.messages.messages, setPage, sortMessagesByDate]
  );

  // Function to refetch the latest emails
  const refetchLatestAllEmail = React.useCallback(async () => {
    setIsSyncMail(true);
    try {
      const latestResponse = await syncMessageList();
      const latestMessages = latestResponse?.data?.messages?.messages || [];
      const nextPageToken = latestResponse?.data?.messages?.nextPageToken || null;
      const lastSyncDate = latestResponse?.data?.lastSyncDate as Date;

      if (!Array.isArray(latestMessages)) {
        throw new Error("Invalid structure for latestMessages");
      }

      const currentMessages = page?.messages.messages || [];
      const existingThreadIds = new Set(currentMessages.map((msg) => msg.threadId));

      // Filter out already existing messages
      const newMessages = latestMessages.filter(
        (msg) => !existingThreadIds.has(msg.threadId)
      );

      // Apply filters and update the page state
      const filteredMessages = filterMessagesByInboxType(newMessages);
      updatePageWithMessages(filteredMessages, nextPageToken, lastSyncDate);

      // Invalidate query to refresh label counts
      queryClient.invalidateQueries({ queryKey: ["getLablesCount"] });
      setIsSyncMail(false);
    } catch (error) {
      console.error("Error fetching latest emails:", error);
    } finally {
      setIsSyncMail(false);
    }
  }, [
    syncMessageList,
    queryClient,
    page?.messages.messages,
    filterMessagesByInboxType,
    updatePageWithMessages,
  ]);

  return {
    refetchLatestAllEmail,
    isSyncMail,
  };
};

export default useRefetchLatestEmail;
