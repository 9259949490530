import { MessageCircleIcon } from "lucide-react"

export default function Contact() {
  return (
    <div className="mx-auto bg-white rounded-lg shadow-md overflow-hidden mt-4 sticky top-[429px]">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="w-12 h-12 bg-[#ff6b391f] rounded-lg flex items-center justify-center">
            <MessageCircleIcon className="w-6 h-6 text-[#ff6c39]" />
          </div>
          <span className="text-sm text-gray-500">24/7 Support</span>
        </div>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Contact us</h2>
        <p className="text-sm text-gray-600 mb-4">
          If you're interested in upgrading your plan or have any questions, our team is here to help you anytime.
        </p>
        <button className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out">
          Discuss upgrade options
        </button>
      </div>
    </div>
  )
}