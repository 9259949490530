import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import Plain from "@/components/Plain";
import AppLoader from "@/components/Apploader";
import Nav from "@/components/Navbars/Nav";
// import ReleaseNote from "@/components/ReleaseNote";

import wingg from "@/assets/Wingg-Final.svg";

import { useToken } from "@/providers/useAuthProvider";
import { useUser } from "@/providers/userProvider";

import useHead from "@/hooks/useHead";

import useGetUser from "@/hooks/useGetUser";

import { appRoutes } from "@/utils/paths";
import storage from "@/utils/storage";

import OrgnizationScreen from "@/components/onboardScreen/OrgnizationScreen";
import SelectPlan from "@/components/onboardScreen/SelectPlanScreen";
import HalfScreen from "@/components/onboardScreen/HalfScreen";
import Profession from "@/components/onboardScreen/ProfessionScreen";
import WelcomeScreen from "@/components/onboardScreen/WelcomeScreen";

const Dashboard = () => {
  useHead({
    title: "Dashboard | Wingg",
    description: "Dashboard for Wingg",
  });

  const { pathname } = useLocation();
  const { getUser } = useGetUser();
  const { user } = useUser();
  const token = useToken();

  const [step, setStep] = React.useState(
    user?.onboard_status || "plan_selection"
  );
  React.useEffect(() => {
    if (token.access_token) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.access_token]);

  React.useEffect(() => {
    if (user?.onboard_status === "onboarded") {
      setStep("onboarded");
    }
  }, [step, user]);

  
  if (!Object.keys(token ?? {}).length) {
    storage.set("redirect", pathname);
    return <Navigate to={appRoutes.auth.INDEX} replace />;
  }
  

  return (
    <AppLoader userLoading={!user}>
      {/* <ReleaseNote /> */}
      {(user?.onboard_status === "onboarded" || step === "onboarded") ? (
        <Plain className="flex fixed h-full top-0 left-0 right-0 bottom-0 bg-[#f9f4f1] dark:bg-background">
          <Plain className="overflow-x-hidden">
            <Nav />
          </Plain>
          <Plain className="bg-white w-full mt-3 rounded-xl rounded-tr-none rounded-br-none rounded-bl-none border-solid border-0 border-t border-l dark:bg-background">
            <Outlet />
          </Plain>
        </Plain>
      ) : (
        <Onboarding step={step} setStep={setStep} />
      )}
    </AppLoader>
  );
};

export default Dashboard;

const professions = [
  "Software Developer",
  "Designer",
  "Marketing",
  "Sales",
  "Teacher",
  "Doctor",
  "Engineer",
  "Accountant",
  "Lawyer",
  "Entrepreneur",
  "Student",
  "Other",
];

const professions2 = [
  "Engineering",
  "Marketing",
  "Sales",
  "Customer Support",
  "Human Resources",
  "Finance",
  "Product Management",
  "Design",
  "Operations",
  "Legal",
  "Other",
];

const Onboarding: React.FC<{
  step: string;
  setStep: (
    value:
      | "org_setup"
      | "onboarded"
      | "department_setup"
      | "team_setup"
      | "profession_details"
      | "welcome_screen"
      | "plan_selection"
  ) => void;
}> = ({ setStep, step }) => {
  const [selectedOption, setSelectedOption] = React.useState<
    "INDIVIDUAL" | "ORGAZNIZATION" | null
  >(null);

  const steps =
    selectedOption === "INDIVIDUAL"
      ? ["plan_selection", "profession_details"]
      : ["plan_selection", "profession_details", "org_setup"];

  const currentStepIndex = steps.indexOf(step);

  return (
    <Plain className="flex h-screen fixed top-0 left-0 right-0 bottom-0 w-full">
      {step === "welcome_screen" ? (
        <WelcomeScreen setStep={setStep} />
      ) : (
        <>
          <div className="w-2/5 flex flex-col justify-center p-[40px] relative">
            <div className="absolute top-0 w-full left-0 p-10">
              <img src={wingg} alt="" className="w-[100px] mb-[50px]" />
              <div className="mb-8">
                <div className="flex justify-between items-center w-full mx-auto gap-[20px]">
                  {steps.map((_, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-between w-full"
                    >
                      {index < steps.length && (
                        <div
                          className={`w-full h-1 mt-2 rounded
                            ${
                              index < currentStepIndex
                                ? "bg-green-400"
                                : index === currentStepIndex
                                ? "bg-orange-400"
                                : "bg-gray-300"
                            }
                          `}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <motion.div
              key={step}
              variants={{
                initial: { opacity: 0, x: 50 },
                animate: { opacity: 1, x: 0 },
                exit: { opacity: 0, x: -50 },
              }}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              {step === "profession_details" && (
                <Profession
                  professions={
                    selectedOption === "INDIVIDUAL" ? professions : professions2
                  }
                  setStep={setStep}
                  selectedOption={selectedOption}
                />
              )}
              {step === "plan_selection" && (
                <SelectPlan
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setStep={setStep}
                />
              )}
              {step === "org_setup" && <OrgnizationScreen setStep={setStep} />}
            </motion.div>
          </div>
          <div className="w-3/5 bg-gray-100/70  flex flex-col justify-center items-center m-[40px] rounded-md">
            <AnimatePresence mode="wait">
              <motion.div
                key={step}
                variants={{
                  initial: { opacity: 0, x: 50 },
                  animate: { opacity: 1, x: 0 },
                  exit: { opacity: 0, x: -50 },
                }}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                {step === "profession_details" && (
                  <HalfScreen
                    desc="Tell us about your profession to customize your experience. This helps us tailor Wingg to better fit your needs."
                    heading="Let's Get to Know You"
                    // title="Select Your Profession"
                    video="https://mywingg.com/wp-content/uploads/2024/10/Create-Label-Feature-Wingg.mp4"
                  />
                )}
                {step === "plan_selection" && (
                  <HalfScreen
                    desc="Choose a plan that suits your team’s needs. Get the most out of Wingg with the right features for your business."
                    heading="Pick the Perfect Plan"
                    title="Welcome to Wingg"
                    image="https://mywingg.com/wp-content/uploads/2024/10/ScreenShot-Tool-20241011214937.png"
                  />
                )}
                {step === "org_setup" && (
                  <HalfScreen
                    desc="Welcome to Wingg! Let's get you set up quickly so you can start managing your vendor communications efficiently."
                    heading="Welcome Aboard"
                    title="Getting Started with Wingg"
                    video="https://mywingg.com/wp-content/uploads/2024/09/Subject-URGENT-Invoice-56789-Payment-Overdue-Hi-Name-This-is-a-reminder-that-the-payment-for-Invoice-56789-is-overdue-by-10-days.-Please-process-the-payment-as-soon-as-possible-to-avoid-any.mp4"
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </>
      )}
    </Plain>
  );
};
